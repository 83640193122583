import { TAnswers, TAllQuestion } from "./entity";
import {
	quiz_additional_questions_1,
	quiz_additional_questions_2,
} from "./quiz_additional_questions";
import { QuizFlags, QuizIdentifier } from "./quiz_identifiers";
import { quiz_questions_1, quiz_questions_2 } from "./quiz_questions";

export const getQuizIdentifierFromPath = (
	pathname?: string
): {
	currentQuiz: QuizIdentifier;
} & QuizFlags => {
	const identifiers = Object.values(QuizIdentifier);
	const currentPath = pathname || (window?.location.pathname ?? "/");

	const currentQuiz =
		identifiers.find((identifier) => currentPath.includes(identifier)) ||
		QuizIdentifier.Quiz_1;

	const quizFlags = identifiers.reduce((acc, identifier) => {
		const camelCaseKey = `is${identifier.charAt(0).toUpperCase()}${identifier
			.slice(1)
			.replace(/-(\d)/, (_, char) => `_${char}`)}`;
		acc[camelCaseKey as keyof QuizFlags] = currentQuiz === identifier;
		return acc;
	}, {} as QuizFlags);

	return {
		currentQuiz,
		...quizFlags,
	};
};

export const getQuestionsArrayByQuizIdentifier = (
	quizIdentifier: QuizIdentifier
): TAllQuestion[] => {
	switch (quizIdentifier) {
		case QuizIdentifier.Quiz_1:
			return quiz_questions_1;
		case QuizIdentifier.Quiz_2:
			return quiz_questions_2;
		default:
			return [];
	}
};

export const getAdditionalQuestionsArrayByQuizIdentifier = (
	quizIdentifier: QuizIdentifier
): TAllQuestion[] => {
	switch (quizIdentifier) {
		case QuizIdentifier.Quiz_1:
			return quiz_additional_questions_1;
		case QuizIdentifier.Quiz_2:
			return quiz_additional_questions_2;
		default:
			return [];
	}
};

export const getQuestionById = (
	id: number,
	quizIdentifier: QuizIdentifier
): TAllQuestion | null => {
	const questionsArray = getQuestionsArrayByQuizIdentifier(quizIdentifier);
	return questionsArray.find((item) => item?.id === id) ?? null;
};

export const getAdditionalQuestionById = (
	id: number,
	quizIdentifier: QuizIdentifier
): TAllQuestion | null => {
	const questionsArray =
		getAdditionalQuestionsArrayByQuizIdentifier(quizIdentifier);
	return questionsArray.find((item) => item?.id === id) ?? null;
};

export const saveAnswer = ({
	answers,
	questionId,
	answerId,
}: {
	answers: TAnswers;
	questionId: number;
	answerId: number | number[];
}): TAnswers => {
	const newAnswers = { ...answers };
	newAnswers[questionId] = answerId;
	return newAnswers;
};

export const getSelectedAnswers = (
	json: string,
	questionId: number
): number | undefined => {
	try {
		const data = JSON.parse(json);
		return data[questionId];
	} catch (e) {
		return undefined;
	}
};

export const parseAnswers = (json: string): TAnswers | undefined => {
	try {
		return JSON.parse(json);
	} catch (e) {
		return undefined;
	}
};

export const normalizeAnswers = (
	answers: number | number[] | undefined
): number[] => {
	return Array.isArray(answers) ? answers : [];
};
