import { QuestionType, TAllQuestion } from "../entity";

export const quiz_questions_1: TAllQuestion[] = [
	{
		title: "How are you feeling today?",
		type: QuestionType.column,
		nextId: 20,
		id: 1,
		list: [
			{
				id: 1,
				img: "/1_1.jpeg",
				text: "Great!",
			},
			{
				id: 2,
				img: "/1_2.jpeg",
				text: "More or less",
			},
			{
				id: 3,
				img: "/1_3.jpeg",
				text: "Don't ask",
			},
		],
	},
	{
		title: "What is your age?",
		type: QuestionType.wrap,
		nextId: 30,
		id: 20,
		list: [
			{
				id: 1,
				img: "/2_1.png",
				text: "18-24",
			},
			{
				id: 2,
				img: "/2_2.png",
				text: "25-34",
			},
			{
				id: 3,
				img: "/2_3.png",
				text: "35-44",
			},
			{
				id: 4,
				img: "/2_4.png",
				text: "45+",
			},
		],
	},
	{
		title: "Do you struggle to identify own emotions?",
		type: QuestionType.minRow,
		nextId: 40,
		id: 30,
		list: [
			{
				id: 1,
				emoji: "😌",
				text: "Not really, I understand them",
			},
			{
				id: 2,
				emoji: "🙂",
				text: "Rarely",
			},
			{
				id: 3,
				emoji: "😓",
				text: "Yes, quite often",
			},
			{
				id: 4,
				emoji: "🤷‍♀️",
				text: "I don't know",
			},
		],
	},
	{
		title: "What would you like to explore or improve?",
		type: QuestionType.minRow,
		nextId: 50,
		id: 40,
		list: [
			{
				id: 1,
				text: "Understand my emotions",
			},
			{
				id: 2,
				text: "Get more inner energy",
			},
			{
				id: 3,
				text: "Relieve from stress",
			},
			{
				id: 4,
				text: "Improve self-confidence",
			},
		],
	},
	{
		title: "Pick which word describes you best",
		type: QuestionType.minRow,
		nextId: 60,
		id: 50,
		list: [
			{
				id: 1,
				text: "Active",
			},
			{
				id: 2,
				text: "Intelligent",
			},
			{
				id: 3,
				text: "Fun",
			},
			{
				id: 4,
				text: "Artistic",
			},
			{
				id: 5,
				text: "Loving",
			},
			{
				id: 6,
				text: "Complex",
			},
		],
	},
	{
		title: "Are you satisfied with your work-life balance?",
		type: QuestionType.questionWithBtns,
		nextId: 70,
		id: 60,
		img: "/6_1.jpeg",
		list: [],
		buttons: [
			{
				emoji: "👎️",
				text: "No",
				id: 1,
			},
			{
				emoji: "👍",
				text: "Yes",
				id: 2,
			},
		],
	},
	{
		title: "Did you know?",
		type: QuestionType.text,
		nextId: 80,
		id: 70,
		text: "'Maintaining a healthy work-life balance helps reduce stress and prevent burnout in the workplace, which positively impacts overall mental well-being.' — American Psychological Association (APA)",
		img: "/7_1.jpeg",
		nextBtnTxt: "Continue",
		hideProgress: true,
	},
	{
		title: "How easy is it for you to maintain focus?",
		type: QuestionType.minRow,
		nextId: 90,
		id: 80,
		list: [
			{
				id: 1,
				emoji: "🧘‍♀️",
				text: "Very Easy",
			},
			{
				id: 2,
				emoji: "👌",
				text: "Somewhat Easy",
			},
			{
				id: 3,
				emoji: "😔",
				text: "Somewhat Difficult",
			},
			{
				id: 4,
				emoji: "🫠",
				text: "Very Difficult",
			},
		],
	},
	{
		title: "What do you need for happy life?",
		type: QuestionType.column,
		id: 90,
		nextId: 100,
		list: [
			{
				id: 1,
				img: "/9_1.png",
				text: "Successful career",
			},
			{
				id: 2,
				img: "/9_2.png",
				text: "Being loved",
			},
			{
				id: 3,
				img: "/9_3.png",
				text: "Adventure",
			},
			{
				id: 4,
				img: "/9_4.png",
				text: "Inner Peace",
			},
		],
	},
	{
		title: "What do you prefer to do for self-growth?",
		type: QuestionType.minRow,
		id: 100,
		nextId: 110,
		list: [
			{
				id: 1,
				text: "Buy luxury things",
			},
			{
				id: 2,
				text: "Go to gym",
			},
			{
				id: 3,
				text: "Read books",
			},
			{
				id: 4,
				text: "Use educational products",
			},
			{
				id: 5,
				text: "Hang out with interesting people",
			},
			{
				id: 6,
				text: "Do nothing",
			},
		],
	},
	{
		title: "Which clothing brand would you prefer?",
		type: QuestionType.wrap,
		id: 110,
		nextId: 120,
		additional: true,
		list: [
			{
				id: 1,
				text: "Gucci",
				nextId: 120,
				img: "/11_1.png",
			},
			{
				id: 2,
				text: "Victoria’s Secret",
				nextId: 130,
				img: "/11_2.png",
			},
			{
				id: 3,
				text: "Chanel",
				nextId: 140,
				img: "/11_3.png",
			},
			{
				id: 4,
				text: "Balenciaga",
				nextId: 150,
				img: "/11_4.png",
			},
		],
	},
	{
		id: 160,
		title: "Which place would you escape to?",
		type: QuestionType.wrap,
		nextId: 170,
		list: [
			{ id: 1, text: "Beach", img: "/16_1.png" },
			{ id: 2, text: "Mountains", img: "/16_2.png" },
			{ id: 3, text: "Coffee shop", img: "/16_3.png" },
			{ id: 4, text: "Festival", img: "/16_4.png" },
			{ id: 5, text: "Mall", img: "/16_5.png" },
			{ id: 6, text: "Cinema", img: "/16_6.png" },
		],
	},
	{
		id: 170,
		title: "When was the last time you treated yourself?",
		type: QuestionType.minRow,
		nextId: 180,
		list: [
			{ id: 1, text: "Yesterday" },
			{ id: 2, text: "Last week" },
			{ id: 3, text: "Last month" },
			{ id: 4, text: "I don't remember" },
		],
	},
	{
		id: 180,
		title: "What is your favorite color palette?",
		type: QuestionType.wrap,
		nextId: 190,
		list: [
			{ id: 1, text: "Futuristic", img: "/18_1.png" },
			{ id: 2, text: "Romantic", img: "/18_2.png" },
			{ id: 3, text: "Natural", img: "/18_3.png" },
			{ id: 4, text: "Vintage", img: "/18_4.png" },
		],
	},
	{
		id: 190,
		title: "How do you cope with negative comments?",
		type: QuestionType.minRow,
		nextId: 200,
		list: [
			{ id: 1, text: "I usually ignore them" },
			{ id: 2, text: "I get upset and nervous" },
			{ id: 3, text: "I react strongly" },
		],
	},
	{
		id: 200,
		title: "Which style do you prefer?",
		type: QuestionType.wrap,
		nextId: 210,
		list: [
			{ id: 1, text: "Casual", img: "/19_1.png" },
			{ id: 2, text: "Spicy", img: "/19_2.png" },
			{ id: 3, text: "Sporty", img: "/19_3.png" },
			{ id: 4, text: "Elegant", img: "/19_4.png" },
		],
	},
	{
		id: 210,
		title: "Do you enjoy experimenting with your hairstyle?",
		type: QuestionType.questionWithBtns,
		nextId: 220,
		img: "/20.jpg",
		list: [],
		additional: true,
		buttons: [
			{
				emoji: "👎️",
				text: "No",
				id: 1,
				nextId: 220,
			},
			{
				emoji: "👍",
				text: "Yes",
				id: 2,
				nextId: 230,
			},
		],
	},
	{
		id: 240,
		title: "Do you like the company of yourself, having a glass of wine?",
		type: QuestionType.questionWithBtns,
		nextId: 250,
		img: "/23_1.png",
		buttons: [
			{
				emoji: "👎️",
				text: "No",
				id: 1,
			},
			{
				emoji: "👍",
				text: "Yes",
				id: 2,
			},
		],
	},
	{
		id: 250,
		title: "How do you typically cope with difficult emotions?",
		type: QuestionType.minRow,
		nextId: 260,
		list: [
			{ id: 1, text: "Talking to friends or family" },
			{ id: 2, text: "Journaling or writing" },
			{ id: 3, text: "Exercising or physical activity" },
			{ id: 4, text: "Practicing meditation" },
			{ id: 5, text: "Seeking professional help" },
			{ id: 6, text: "None of the above" },
		],
	},
	{
		id: 260,
		title: "Did you know that…",
		type: QuestionType.text,
		nextId: 270,
		hideProgress: true,
		img: "/25_1.png",
		text: "Maintaining a mood journal can be a powerful method for managing emotions and enhancing overall mental health. By consistently recording and reflecting on your feelings, you can identify patterns and triggers, leading to a deeper understanding of your emotions.",
	},
	{
		id: 270,
		title: "How are you feeling about yourself this week?",
		type: QuestionType.minRow,
		nextId: 280,
		list: [
			{ id: 1, text: "Confident", emoji: "😎" },
			{ id: 2, text: "Alluring", emoji: "💃" },
			{ id: 3, text: "Uncertain", emoji: "😕" },
			{ id: 4, text: "Overwhelmed", emoji: "😓" },
			{ id: 5, text: "Anxious", emoji: "😬" },
		],
	},
	{
		id: 280,
		title: "Which habit is the most troubling to you?",
		type: QuestionType.minRow,
		nextId: 290,
		additional: true,
		list: [
			{ id: 1, text: "Procrastination", nextId: 290 },
			{ id: 2, text: "Overthinking", nextId: 300 },
			{ id: 3, text: "Self-doubt", nextId: 310 },
			{ id: 4, text: "Social-media addiction", nextId: 320 },
			{ id: 5, text: "Lack of self-care", nextId: 330 },
			{ id: 6, text: "None of the above", nextId: 340 },
		],
	},
];
