import { QuestionType, TAllQuestion } from "../entity";

export const quiz_additional_questions_1: TAllQuestion[] = [
	{
		id: 120,
		nextId: 160,
		trackId: 1600,
		type: QuestionType.text,
		title: "Great choice!",
		text: "Great choice! People who chose Gucci are more likely to have a personality that values luxury, status, and high fashion. You have a strong sense of style and an appreciation for bold, distinctive designs. Seems like you seek to make a statement with your choices and enjoy being perceived as fashionable and sophisticated.",
		img: "/12_1.jpg",
		nextBtnTxt: "Continue",
		additionalStyles: {
			widthBorder: true,
			italic: true,
		},
	},
	{
		id: 130,
		nextId: 160,
		trackId: 1600,
		type: QuestionType.text,
		title: "Great choice!",
		text: "Those who prefer Victoria's Secret tend to appreciate feminine, alluring styles and prioritize comfort and confidence. You likely value sensuality and like to express your personality through your wardrobe, often favoring feminine and comfortable items.",
		img: "/13_1.jpg",
		nextBtnTxt: "Continue",
		additionalStyles: {
			widthBorder: true,
			italic: true,
		},
	},
	{
		id: 140,
		nextId: 160,
		trackId: 1600,
		type: QuestionType.text,
		title: "Great choice!",
		text: "Choosing Chanel suggests a refined taste and a timeless elegance. You are drawn to sophistication, understated luxury, and impeccable craftsmanship. You exude confidence and sophistication effortlessly, favoring chic and iconic designs that stand the test of time.",
		img: "/14_1.jpg",
		nextBtnTxt: "Continue",
		additionalStyles: {
			widthBorder: true,
			italic: true,
		},
	},
	{
		id: 150,
		nextId: 160,
		trackId: 1600,
		type: QuestionType.text,
		title: "Great choice!",
		text: "Opting for Balenciaga indicates a preference for avant-garde, edgy fashion that pushes boundaries. You are likely to embrace bold experimentation and unconventional style, seeking to make a statement with your clothing choices. Your taste leans towards modern, urban aesthetics with a touch of rebellion.",
		img: "/15_1.jpg",
		nextBtnTxt: "Continue",
		additionalStyles: {
			widthBorder: true,
			italic: true,
		},
	},
	{
		id: 220,
		nextId: 240,
		trackId: 2400,
		type: QuestionType.text,
		title: "Great!",
		text: "Seems like you value stability and consistency and prioritize practicality over novelty.",
		img: "/21_1.png",
		nextBtnTxt: "Continue",
		additionalStyles: {
			italic: true,
		},
	},
	{
		id: 230,
		nextId: 240,
		trackId: 2400,
		type: QuestionType.text,
		title: "Cool!",
		text: "Seems like you are a flexible person, able to embrace change and new experiences easily.",
		img: "/22_1.png",
		nextBtnTxt: "Continue",
		additionalStyles: {
			center: true,
			italic: true,
		},
	},
	{
		id: 290,
		nextId: -1,
		trackId: 1000,
		type: QuestionType.text,
		title: "Procrastination",
		text: "Procrastination is often linked to higher levels of stress, anxiety, and depression. About 75% of procrastinators report higher stress levels compared to non-procrastinators.",
		img: "/29_0.png",
		nextBtnTxt: "Continue",
		additionalStyles: {
			center: true,
			italic: true,
		},
	},
	{
		id: 300,
		nextId: -1,
		trackId: 1000,
		type: QuestionType.text,
		title: "Overthinking",
		text: "Can lead to increased anxiety, distractibility, and loss of energy. Constantly dwelling and analyzing situations can lead to mental overload and hinder decision-making.",
		img: "/29_1.png",
		nextBtnTxt: "Continue",
		additionalStyles: {
			center: true,
			italic: true,
		},
	},
	{
		id: 310,
		nextId: -1,
		trackId: 1000,
		type: QuestionType.text,
		title: "Self-doubt",
		text: "Hinders goal achievement and self-realization. Accompanied by uncertainty in one's abilities and doubts about one's worth, which can limit personal growth.",
		img: "/30_1.png",
		nextBtnTxt: "Continue",
		additionalStyles: {
			center: true,
			italic: true,
		},
	},
	{
		id: 320,
		nextId: -1,
		trackId: 1000,
		type: QuestionType.text,
		title: "Social-media addiction",
		text: "Disrupts real-life relationships, productivity, and mental well-being. Excessive use of social media platforms can lead to feelings of isolation, decreased self-esteem, and an inability to focus on real-world activities.",
		img: "/31_1.png",
		nextBtnTxt: "Continue",
		additionalStyles: {
			center: true,
			italic: true,
		},
	},
	{
		id: 330,
		nextId: -1,
		trackId: 1000,
		type: QuestionType.text,
		title: "Lack of self-care",
		text: "Neglecting self-care can lead to physical and mental health issues, burnout, and decreased overall well-being. Failing to prioritize one's own needs can result in increased stress, fatigue, and a diminished capacity to cope with life's challenges.",
		img: "/32_1.png",
		nextBtnTxt: "Continue",
		additionalStyles: {
			center: true,
			italic: true,
		},
	},
	{
		id: 340,
		nextId: -1,
		trackId: 1000,
		type: QuestionType.text,
		title: "None of the above",
		text: "If none of the options trouble you, it's essential to maintain awareness of potential areas for improvement in your life. Reflecting on your habits and behaviors can help you continue to grow and thrive personally and professionally.",
		img: "/33_1.png",
		nextBtnTxt: "Continue",
		additionalStyles: {
			center: true,
			italic: true,
		},
	},
];
