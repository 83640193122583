export enum QuestionType {
	wrap = "wrap",
	column = "column",
	minRow = "minRow",
	minRowMultiple = "minRowMultiple",
	questionWithBtns = "questionWithBtns",
	custom = "custom",
	slider = "slider",
	text = "text",
}

export enum QuestionSubtitleVariant {
	small = "small",
	big = "big",
}

interface IQuestionSubtitle {
	text: string;
	variant: QuestionSubtitleVariant;
}

export interface IQuestionBase {
	title: string;
	subtitle?: IQuestionSubtitle;
	type: QuestionType;
	nextId: number;
	id: number;
	nextBtnTxt?: string;
	additional?: boolean;
	trackId?: number;
	hideProgress?: boolean;
}

export interface IQuestion extends IQuestionBase {
	list: TAnswer[];
}

export interface IQuestionWithBtns extends IQuestionBase {
	img: string;
	buttons: TButton[];
}

export interface IQuestionWithText extends IQuestionBase {
	text: string;
	img: string;
	additionalStyles?: {
		widthBorder?: boolean;
		center?: boolean;
		italic?: boolean;
	};
}

export interface IQuestionSlider extends IQuestionBase {
	min: string;
	max: string;
}

export type TAnswers = Record<number, number | number[]>;
export type TAllQuestion =
	| IQuestion
	| IQuestionWithBtns
	| IQuestionWithText
	| IQuestionSlider
	| undefined;

export type TAnswer = {
	id: number;
	img?: string;
	emoji?: string;
	text: string;
	nextId?: number;
};

type TButton = {
	emoji: string;
	text: string;
	id: number;
	nextId?: number;
};
