export enum QuizIdentifier {
	Quiz_1 = "quiz-1",
	Quiz_2 = "quiz-2",
}

export type QuizFlags = {
	-readonly [K in keyof typeof QuizIdentifier as `is${Capitalize<
		string & K
	>}`]: boolean;
};
