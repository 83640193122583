export enum EventsType {
	Close = "Close",
	Debug = "Debug",
	Token = "Token",
}

export default function Postman(type: EventsType, data?: any) {
	try {
		if (type == EventsType.Token) {
			// @ts-ignore
			window.AppChanel?.postMessage(JSON.stringify({ type, tokens: data }));
		} else {
			// @ts-ignore
			window.AppChanel?.postMessage(JSON.stringify({ type, data }));
		}
	} catch (e) {
		console.error(e);
	}
}
