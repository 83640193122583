import { QuestionSubtitleVariant, QuestionType, TAllQuestion } from "../entity";

export const quiz_questions_2: TAllQuestion[] = [
	{
		title: "Choose your age",
		type: QuestionType.wrap,
		id: 1,
		nextId: 20,
		list: [
			{
				id: 1,
				img: "/2_1.png",
				text: "18-24",
			},
			{
				id: 2,
				img: "/2_2.png",
				text: "25-34",
			},
			{
				id: 3,
				img: "/2_3.png",
				text: "35-44",
			},
			{
				id: 4,
				img: "/2_4.png",
				text: "45+",
			},
		],
	},
	{
		title: "In your childhood, your parents were...",
		type: QuestionType.minRow,
		id: 20,
		nextId: 30,
		list: [
			{
				id: 1,
				text: "Happily married",
			},
			{
				id: 2,
				text: "Unhappily married",
			},
			{
				id: 3,
				text: "They weren't married",
			},
			{
				id: 4,
				text: "Divorced",
			},
			{
				id: 5,
				text: "I had only one parent",
			},
			{
				id: 6,
				text: "I had no parents",
			},
		],
	},
	{
		title: "How can you describe the environment you were growing up in?",
		subtitle: {
			text: "Choose as many as you like",
			variant: QuestionSubtitleVariant.small,
		},
		type: QuestionType.minRowMultiple,
		id: 30,
		nextId: 40,
		list: [
			{
				id: 1,
				text: "Nurturing and supportive",
			},
			{
				id: 2,
				text: "Chaotic and unpredictable",
			},
			{
				id: 3,
				text: "Challenging and demanding",
			},
			{
				id: 4,
				text: "Economic hardship and scarcity",
			},
			{
				id: 5,
				text: "Neglectful and lonely",
			},
		],
		nextBtnTxt: "Next",
	},
	{
		title: "Which memories from your childhood are prevalent?",
		type: QuestionType.minRow,
		id: 40,
		nextId: 50,
		list: [
			{
				id: 1,
				emoji: "🌟",
				text: "Positive",
			},
			{
				id: 2,
				emoji: "🌧️",
				text: "Negative",
			},
			{
				id: 3,
				emoji: "🤔",
				text: "I don't remember my childhood",
			},
			{
				id: 4,
				emoji: "🤷‍♀️",
				text: "Not sure",
			},
		],
	},
	{
		title: "Did playing with dolls make you feel better as a child?",
		type: QuestionType.minRow,
		id: 50,
		nextId: 60,
		list: [
			{
				id: 1,
				emoji: "👍",
				text: "Yes",
			},
			{
				id: 2,
				emoji: "👎️",
				text: "No",
			},
			{
				id: 3,
				emoji: "🤷‍♀️",
				text: "Not sure",
			},
		],
	},
	{
		title: "Do you tend to change your behavior to fit in with different social groups or situations?",
		type: QuestionType.minRow,
		id: 60,
		nextId: 70,
		list: [
			{
				id: 1,
				emoji: "👍",
				text: "Yes",
			},
			{
				id: 2,
				emoji: "👎️",
				text: "No",
			},
			{
				id: 3,
				emoji: "🤷‍♀️",
				text: "Not sure",
			},
		],
	},
	{
		title: "What situations trigger emotional reactions or flashbacks from your past?",
		subtitle: {
			text: "Choose as many as you like",
			variant: QuestionSubtitleVariant.small,
		},
		type: QuestionType.minRowMultiple,
		id: 70,
		nextId: 80,
		list: [
			{
				id: 1,
				text: "Those relating to any abuse",
			},
			{
				id: 2,
				text: "When my feelings are neglected",
			},
			{
				id: 3,
				text: "When feeling helpless",
			},
			{
				id: 4,
				text: "When feeling trapped",
			},
			{
				id: 5,
				text: "Those related to loss",
			},
			{
				id: 6,
				text: "Other",
			},
		],
		nextBtnTxt: "Next",
	},
	{
		title: "Have you ever experienced chronic pelvic pain that has been difficult to diagnose or treat?",
		type: QuestionType.minRow,
		id: 80,
		nextId: 90,
		list: [
			{
				id: 1,
				emoji: "👍",
				text: "Yes",
			},
			{
				id: 2,
				emoji: "👎️",
				text: "No",
			},
			{
				id: 3,
				emoji: "🤷‍♀️",
				text: "Not sure",
			},
		],
	},
	{
		title: "Can you relate to this statement?",
		subtitle: {
			text: "“I often have panic attacks or anxiety triggered by childhood memories.”",
			variant: QuestionSubtitleVariant.big,
		},
		type: QuestionType.slider,
		id: 90,
		nextId: 100,
		min: "Can't relate",
		max: "Strongly relate",
		nextBtnTxt: "Next",
	},
	{
		title: 'Do you see depression or low mood as "normal" or just a part of who you are?',
		type: QuestionType.minRow,
		id: 100,
		nextId: 110,
		list: [
			{
				id: 1,
				emoji: "👍",
				text: "Yes",
			},
			{
				id: 2,
				emoji: "👎️",
				text: "No",
			},
			{
				id: 3,
				emoji: "🤷‍♀️",
				text: "Not sure",
			},
		],
	},
	{
		title: "Have you ever used a mood journal to track your emotions?",
		type: QuestionType.minRow,
		id: 110,
		nextId: 120,
		list: [
			{
				id: 1,
				emoji: "👍",
				text: "Yes",
			},
			{
				id: 2,
				emoji: "👎️",
				text: "No",
			},
			{
				id: 3,
				emoji: "🤷‍♀️",
				text: "Not sure",
			},
		],
	},
	{
		title: "Did you know?",
		type: QuestionType.text,
		id: 120,
		nextId: 130,
		img: "/25_1.png",
		text: "Mood journaling is an effective tool for managing mental health, particularly in depression, anxiety, and PTSD.\n\nIt enhances emotional awareness, helps identify negative thought patterns, and reduces symptoms by promoting self-reflection and cognitive restructuring.",
		nextBtnTxt: "Next",
		additionalStyles: {
			italic: false,
		},
	},
	{
		title: "Can you relate to this statement?",
		subtitle: {
			text: "”I use jokes or sarcasm to deal with difficult memories from my past.”",
			variant: QuestionSubtitleVariant.big,
		},
		type: QuestionType.slider,
		id: 130,
		nextId: 140,
		min: "Can't relate",
		max: "Strongly relate",
		nextBtnTxt: "Next",
	},
	{
		title: "What do you feel when being in the spotlight?",
		subtitle: {
			text: "Choose as many as you like",
			variant: QuestionSubtitleVariant.small,
		},
		type: QuestionType.minRowMultiple,
		id: 140,
		nextId: 150,
		list: [
			{
				id: 1,
				emoji: "😌",
				text: "Satisfaction",
			},
			{
				id: 2,
				emoji: "😰",
				text: "Stress",
			},
			{
				id: 3,
				emoji: "😶‍🌫️",
				text: "Disconnection",
			},
			{
				id: 4,
				emoji: "😳",
				text: "Shyness",
			},
		],
		nextBtnTxt: "Next",
	},
	{
		title: "Have you ever noticed that new clothes make you feel better?",
		type: QuestionType.minRow,
		id: 150,
		nextId: 160,
		list: [
			{
				id: 1,
				emoji: "👍",
				text: "Yes",
			},
			{
				id: 2,
				emoji: "👎️",
				text: "No",
			},
			{
				id: 3,
				emoji: "🤷‍♀️",
				text: "Not sure",
			},
		],
	},
	{
		title: "In your relationships with others you often...",
		subtitle: {
			text: "Choose as many as you like",
			variant: QuestionSubtitleVariant.small,
		},
		type: QuestionType.minRowMultiple,
		id: 160,
		nextId: 170,
		list: [
			{
				id: 1,
				text: "Keep the distance",
			},
			{
				id: 2,
				text: "Open up easily",
			},
			{
				id: 3,
				text: "Try not to get attached",
			},
			{
				id: 4,
				text: "Attach too quickly",
			},
			{
				id: 5,
				text: "Struggle to express your feelings",
			},
			{
				id: 6,
				text: "Avoid conflict at all costs",
			},
			{
				id: 7,
				text: "Other",
			},
		],
		nextBtnTxt: "Next",
	},
	{
		title: "Can you relate to this statement?",
		subtitle: {
			text: "”I'm repeatedly drawn to people who treat me poorly.”",
			variant: QuestionSubtitleVariant.big,
		},
		type: QuestionType.slider,
		id: 170,
		nextId: 180,
		min: "Can't relate",
		max: "Strongly relate",
		nextBtnTxt: "Next",
	},
	{
		title: "We understand that each healing journey is unique",
		type: QuestionType.text,
		id: 180,
		nextId: 190,
		img: "/1_1.jpeg",
		text: "So we use a trauma-informed approach, sensitive to each person's specific needs and experiences.",
		nextBtnTxt: "Next",
		additionalStyles: {
			italic: false,
		},
	},
	{
		title: "What do you feel when seeing some children receive what you lacked in childhood?",
		subtitle: {
			text: "Choose as many as you like",
			variant: QuestionSubtitleVariant.small,
		},
		type: QuestionType.minRowMultiple,
		id: 190,
		nextId: 200,
		list: [
			{
				id: 1,
				text: "Sadness",
			},
			{
				id: 2,
				text: "Grief",
			},
			{
				id: 3,
				text: "Anger",
			},
			{
				id: 4,
				text: "I didn't feel any lack",
			},
		],
		nextBtnTxt: "Next",
	},
	{
		title: "Have you ever struggled with a fear of being judged or rejected by others?",
		type: QuestionType.minRow,
		id: 200,
		nextId: 210,
		list: [
			{
				id: 1,
				emoji: "👍",
				text: "Yes",
			},
			{
				id: 2,
				emoji: "👎️",
				text: "No",
			},
			{
				id: 3,
				emoji: "🤷‍♀️",
				text: "Not sure",
			},
		],
	},
	{
		title: "Can you relate to this statement?",
		subtitle: {
			text: "”I feel like there is a delay between my feelings and my ability to articulate them.”",
			variant: QuestionSubtitleVariant.big,
		},
		type: QuestionType.slider,
		id: 210,
		nextId: 220,
		min: "Can't relate",
		max: "Strongly relate",
		nextBtnTxt: "Next",
	},
	{
		title: "Do you struggle with low self-worth or self-esteem?",
		type: QuestionType.minRow,
		id: 220,
		nextId: 230,
		list: [
			{
				id: 1,
				emoji: "👍",
				text: "Yes",
			},
			{
				id: 2,
				emoji: "👎️",
				text: "No",
			},
			{
				id: 3,
				emoji: "🤷‍♀️",
				text: "Not sure",
			},
		],
	},
	{
		title: "What do you expect to get out of healing your childhood trauma?",
		subtitle: {
			text: "Choose as many as you like",
			variant: QuestionSubtitleVariant.small,
		},
		type: QuestionType.minRowMultiple,
		id: 230,
		nextId: 240,
		list: [
			{
				id: 1,
				text: "Improve my love life",
			},
			{
				id: 2,
				text: "Enhance overall happiness",
			},
			{
				id: 3,
				text: "Improve my career",
			},
			{
				id: 4,
				text: "Reconnect with my parents",
			},
			{
				id: 5,
				text: "Accept myself as I am",
			},
		],
		nextBtnTxt: "Next",
	},
	{
		title: "How much time are you willing to dedicate to a personalized plan to heal your trauma?",
		type: QuestionType.minRow,
		id: 240,
		nextId: -1,
		list: [
			{
				id: 1,
				text: "2 weeks",
			},
			{
				id: 2,
				text: "1 month",
			},
			{
				id: 3,
				text: "2-3 months",
			},
			{
				id: 4,
				text: "6 months",
			},
			{
				id: 5,
				text: "I don't want to heal it",
			},
		],
	},
];
