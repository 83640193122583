import { useLocation } from "@remix-run/react";
import { motion } from "framer-motion";
import { PropsWithChildren } from "react";
import { getQuizIdentifierFromPath } from "~/data";

export default function PageTransition({ children }: PropsWithChildren) {
	const { pathname } = useLocation();
	const { isQuiz_2 } = getQuizIdentifierFromPath();

	const shouldCenter = isQuiz_2 && pathname === "/generate";

	return (
		<motion.div
			id="motion_div"
			key={pathname}
			transition={{ ease: "easeInOut", duration: 0.4 }}
			initial={{ x: "100%", opacity: 0 }}
			animate={{ x: "0", opacity: 1 }}
			exit={{ x: "-100%", opacity: 0 }}
			style={{
				justifyContent: shouldCenter ? "center" : "flex-start",
			}}
		>
			{children}
		</motion.div>
	);
}
